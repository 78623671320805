<template>
  <SubPageWrapper width="full">
    <!-- <CreateSupplierModal /> -->

    <template #loader>
      <SlOverlay :show="ordersCreating" />
    </template>
    <template #breadcrumbs>
      <SlBreadcrumb @click="handleGoToInventory">
        {{ $t('Main.tabNames.inventory') }}
      </SlBreadcrumb>
      <SlBreadcrumb
        :index="1"
        active
      >
        {{ $t('Web.Suppliers.PageTitle') }}
      </SlBreadcrumb>
    </template>
    <template #actions>
      <SlButton
        variant="secondary"
        color="grey"
        :disabled="!isEdited"
        @click="handleDiscardChanges"
      >
        {{ $t('Web.Collections.Discard') }}
      </SlButton>
      <SlButton
        :disabled="!isEdited"
        @click="handleSaveChanges"
      >
        {{ $t('Web.Collections.SaveChanges') }}
      </SlButton>
    </template>
    <ContentBlock>
      <div class="subpage__actions">
        <SlSearchInput
          :value="search"
          class="w-280"
          @submit="handleSearch"
        />
        <div class="subpage__actions-group">
          <SlSelectionInfo
            v-if="isActionsVisible"
            @shortcut:delete="handleDelete"
          >
            <template
              v-if="selectedRowsCount"
              #description
            >
              {{ $t('Web.Selection', { 1: selectedRowsCount }) }}
            </template>
            <template #actions>
              <SlButton
                variant="primary"
                color="accent-red"
                @click="handleDelete"
              >
                <template #prepend>
                  <icon
                    data="@icons/trash.svg"
                    class="fill-off size-16 color-white-stroke"
                  />
                </template>
                {{ $t('EditCollections.Ui.butRemoveContainer') }}
              </SlButton>
            </template>
          </SlSelectionInfo>
          <template v-else>
            <!-- <SlButton
              variant="outlined"
              @click="handleAddSupplier"
            >
              <template #prepend>
                <icon
                  data="@icons/plus.svg"
                  class="fill-off size-16"
                />
              </template>
              {{ $t('Web.Suppliers.AddSupplier') }}
            </SlButton> -->
            <SlButton
              variant="secondary"
              color="grey"
              @click="handleExportToXlsx"
            >
              {{ $t('PlannedOrders.Ui.btExportToExcel') }}
            </SlButton>
          </template>
        </div>
      </div>
      <div class="subpage__table-wrapper">
        <div class="subpage__table">
          <SlTable
            id="suppliers-table"
            :headers="tableHeaders"
            :rows="tableRows"
            unique-key="id"
            header-unique-key="key"
            header-name-key="name"
            header-type-key="type"
            :hidden-columns-keys="['id', checkboxKey]"
            :max-height="1100"
            :value-parser="parseValue"
            pagination
            :total-rows-count="totalRowsCount"
            :current-page="currentPage"
            :per-page="perPage"
            :loading="loading"
            :inner-no-data="!!search"
            num-col
            highlight-row
            @page-change="handlePaginationChange"
            @per-page-change="handlePerPageChange"
            @sort="handleSort"
            @contextmenu:clipboard="handleContextMenu"
          >
            <template #loader>
              <SlOverlay :show="loading" />
            </template>

            <!-- <template #header-checkbox>
              <SlCheckbox
                :value="headerCheckboxValue"
                :indeterminate="isIndeterminate"
                @change="handleSelectRowsAll"
              />
            </template> -->

            <!-- <template #row-checkbox="{ rowId, row }">
              <SlCheckbox
                :value="isRowSelected(row)"
                @change="(val) => handleSelectRows(val, rowId)"
              />
            </template> -->

            <template #LeadTime="slotScope">
              <SlTableInput
                :value="getInputValue(slotScope)"
                :foreground="getInputForeground(slotScope)"
                @submit="(val) => handleCellSubmit({ val, slotScope })"
              />
            </template>

            <template #PurchasePrice="slotScope">
              <SlTableInput
                :value="getInputValue(slotScope)"
                :text-value="getPurchaseValue(slotScope)"
                :foreground="getInputForeground(slotScope)"
                :input-regexp="regExp.floatInput"
                :submit-regexp="regExp.floatSubmit"
                @submit="(val) => handleCellSubmit({ val, slotScope })"
              />
            </template>

            <template #Rounding="slotScope">
              <SlTableInput
                :value="getInputValue(slotScope)"
                :foreground="getInputForeground(slotScope)"
                @submit="(val) => handleCellSubmit({ val, slotScope })"
              />
            </template>

            <template #MinLot="slotScope">
              <SlTableInput
                :value="getInputValue(slotScope)"
                :foreground="getInputForeground(slotScope)"
                @submit="(val) => handleCellSubmit({ val, slotScope })"
              />
            </template>

            <template #no-data>
              <SlNoData>
                <template #image>
                  <icon
                    :data="noDataMeta.icon"
                    :class="{
                      'illustration-md': !search,
                      'size-48': search,
                      'fill-off': search
                    }"
                  />
                </template>
                <template #title>
                  {{ noDataMeta.title }}
                </template>
                <template #text>
                  {{ noDataMeta.text }}
                </template>
              </SlNoData>
            </template>
          </SlTable>
        </div>
      </div>
    </ContentBlock>
  </SubPageWrapper>
</template>

<script>
import { mapActions, mapState } from 'vuex';
// import CreateSupplierModal from '@/components/Modals/CreateSupplierModal';
import { modal } from '@/mixins/modal';
import { fileSaver } from '@/mixins/webAPI';
import { tableConfig } from '@/mixins/tableConfig';
import { access } from '@/mixins/access';
import modalIds from '@/config/shared/modalsId.config';
import { checkboxStates } from '@/config/shared/slTable.config';
import { routeNames } from '@/config/router/router.config';
import { preventTabClose } from '@/helpers/shared/webAPI';
import { formatNumber } from '@/helpers/format/formatNumber';
import regExp from '@/helpers/utils/regExp';

export default {
  name: 'Suppliers',
  components: {
    // CreateSupplierModal
  },
  mixins: [modal, fileSaver, tableConfig, access],
  data() {
    return {
      modalIds,
      regExp,
      isRedirect: false,
      loading: false,
      ordersCreating: false,
      checkboxKey: 'Enabled'
    };
  },
  computed: {
    ...mapState({
      table: (state) => state.suppliers.table,
      tableConfig: (state) => state.suppliers.tableConfig
    }),
    tableMetaInfo() {
      return this.table?.metadata?.calculatedValues;
    },
    checkboxHeader() {
      return this.table?.metadata?.checkbox;
    },
    isEdited() {
      return this.table?.metadata?.edited;
    },
    tableHeaders() {
      return this.table?.headers || [];
    },
    showColumnsOptions() {
      return this.table?.metadata?.availableCols?.filter(header => header.key !== this.checkboxKey) || [];
    },
    tableRows() {
      return this.table?.rows || [];
    },
    totalRowsCount() {
      return this.table?.totalRowsCount;
    },
    currentPage() {
      return this.table?.currentPage;
    },
    isIndeterminate() {
      return this.checkboxHeader === checkboxStates.MIXED;
    },
    // headerCheckboxValue() {
    //   return this.checkboxHeader !== checkboxStates.UNCHECKED;
    // },
    selectedRowsCount() {
      return this.table?.metadata?.selectedRowsCount;
    },
    isActionsVisible() {
      return !this.loading
        && (this.headerCheckboxValue || this.selectedRowsCount > 0)
        && this.tableRows.length;
    },
    noDataMeta() {
      if (this.search) {
        return {
          icon: require('@icons/style_no_search_results_double.svg'),
          title: this.$t('Web.NoData.SearchTitle'),
          text: this.$t('Web.NoData.SearchText')
        };
      }

      return {
        icon: require('@icons/illustration/no-table-rows.svg'),
        title: this.$t('Web.NoData.DefaultTitle'),
        text: ''
      };
    }
  },
  watch: {
    isEdited(val) {
      preventTabClose(val);
    }
  },
  async beforeMount() {
    await this.fetchSuppliersTable();
  },
  beforeDestroy() {
    preventTabClose();
  },
  destroyed() {
    this.resetState();
  },
  beforeRouteLeave(_, __, next) {
    if (!this.isRedirect) {
      return this.onRouteLeave(next);
    }

    next();
  },
  methods: {
    ...mapActions({
      fetchSuppliers: 'suppliers/fetchSuppliers',
      // createSupplier: 'suppliers/createSupplier',
      submitCell: 'suppliers/submitCell',
      selectRow: 'suppliers/selectRow',
      exportToXlsx: 'suppliers/exportToXlsx',
      updateOverrides: 'suppliers/updateOverrides',
      saveOverrides: 'suppliers/saveOverrides',
      discardOverrides: 'suppliers/discardOverrides',
      resetState: 'suppliers/resetState',
      deleteRows: 'suppliers/deleteRows',
      updateTableConfig: 'suppliers/updateTableConfig'
    }),
    async fetchSuppliersTable() {
      try {
        this.loading = true;

        await this.fetchSuppliers();
      } finally {
        this.loading = false;
      }
    },
    // handleAddSupplier() {
    //   this.showModal(modalIds.CREATE_SUPPLIER, {
    //     createCallback: this.createSupplier
    //   });
    // },
    handleGoToInventory() {
      this.$router.push({
        name: routeNames.INVENTORY
      });
    },
    // search
    handleSearch(query) {
      if (!this.search && !this.totalRowsCount) {
        return;
      }

      this.search = query;

      this.fetchSuppliersTable();
    },
    // route leave
    onRouteLeave(next) {
      if (!this.isEdited) {
        return next();
      }

      this.showModal(modalIds.SL_CONFIRM_MODAL, {
        title: this.$t('Web.Modals.UnsavedConfirm.TitleUnsavedChanges'),
        text: this.$t('Web.Modals.UnsavedConfirm.TextUnsavedChanges'),
        icon: 'style_save_double',
        discardText: this.$t('Web.Modals.UnsavedConfirm.ButtonExitDiscard'),
        confirmText: this.$t('Web.Modals.UnsavedConfirm.ButtonSave'),
        discardCallback: () => {
          this.isRedirect = true;

          this.discardOverrides();

          next();
        },
        confirmCallback: async() => {
          await this.handleSaveChanges();

          this.isRedirect = true;

          next();
        }
      });
    },
    // export
    getFileName() {
      return this.$t('Web.Suppliers.PageTitle');
    },
    handleExportToXlsx() {
      this.saveFile(this.exportToXlsx.bind(this));
    },
    // row selection
    // isRowSelected(row) {
    //   return row[this.checkboxKey]?.val;
    // },
    // handleSelectRowsAll(checked) {
    //   this.updateOverrides({
    //     [this.checkboxKey]: checked
    //   });
    // },
    // handleSelectRows(checked, id) {
    //   this.selectRow({
    //     value: checked,
    //     rowId: id,
    //     cellKey: this.checkboxKey
    //   });

    //   this.updateOverrides({
    //     id,
    //     [this.checkboxKey]: checked
    //   });
    // },
    // table configuration
    getPurchaseValue({ cellValue }) {
      const currency = cellValue.currency || '';
      const value = formatNumber(cellValue.val || cellValue.auto);

      if (currency) {
        return `${currency} ${value}`;
      }

      return value;
    },
    parseValue(cellValue) {
      if (!cellValue) {
        return '';
      }

      if (typeof cellValue !== 'object') {
        return cellValue;
      }

      return cellValue.val || cellValue.auto || '';
    },
    getInputValue(scope) {
      return scope.cellValue?.val || scope.cellValue?.auto || '';
    },
    getInputForeground(scope) {
      const val = scope.cellValue?.val;

      if (val) {
        return 'over';
      }

      return '';
    },
    handlePaginationChange(page) {
      this.page = page;

      this.fetchSuppliersTable();
    },
    handlePerPageChange(pageSize) {
      this.perPage = pageSize;
      this.page = 1;

      this.fetchSuppliersTable();
    },
    async handleSort({ callback }) {
      const { key, order } = callback();

      this.sortOrder = order;
      this.colClass = key;

      try {
        this.loading = true;

        await this.setSortingParams({
          config: this.tableConfig,
          params: {
            table: 'suppliers'
          }
        });
      } finally {
        this.loading = false;
      }
    },
    formatCellValue(val) {
      if (val === null) {
        return val;
      }

      return +val;
    },
    handleCellSubmit({ val, slotScope }) {
      this.submitCell({
        value: val,
        rowId: slotScope.rowId,
        cellKey: slotScope.cellKey
      });

      this.updateOverrides({
        id: slotScope.rowId,
        [slotScope.cellKey]: this.formatCellValue(val)
      });
    },
    // actions
    handleDelete() {
      this.showModal(modalIds.SL_CONFIRM_MODAL, {
        title: this.$t('Web.Modals.Delete.Title', {
          1:  this.$t('Web.Pluralization.Item.val', {
            p1: this.$tc('Web.Pluralization.Item.p1', this.selectedRowsCount, { 1: this.selectedRowsCount })
          })
        }),
        text: this.$t('Web.Modals.Delete.DescriptionBulk', {
          1: this.$tc('Web.Pluralization.ItThem', this.selectedRowsCount)
        }),
        icon: 'style_warning_double',
        confirmText: this.$t('Web.Modals.ActionConfirm.ButtonDelete'),
        confirmCallback: this.deleteRows
      });
    },
    handleDiscardChanges() {
      if (this.isEdited) {
        this.showModal(modalIds.SL_CONFIRM_MODAL, {
          title: this.$t('Web.Modals.UnsavedConfirm.TitleDiscardChanges'),
          text: this.$t('Web.Modals.UnsavedConfirm.TextDiscardChanges'),
          icon: 'style_save_double',
          confirmText: this.$t('Web.Collections.Discard'),
          confirmCallback: this.discardOverrides
        });
      }
    },
    async handleSaveChanges() {
      await this.saveOverrides();

      this.$notify({
        type: 'success',
        title: this.$t('Common.Save'),
        text: this.$t('Web.Notification.TitleSavedSuccess')
      });
    },
    handleContextMenu(callback) {
      callback({ updateCallback: this.handleCellSubmit });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/utils/_color.scss";
@import "@/style/utils/_variable.scss";
@import "@/style/utils/_mixins.scss";

.subpage-wrapper {
  ::v-deep {
    .subpage-wrapper__main-header {
      min-height: 32px;
    }

    .subpage-wrapper__main {
      height: calc(100vh - 40px) !important;
    }
  }
}

.content-block {
  .meta-info {
    display: flex;
    align-items: center;
    column-gap: 20px;
    width: fit-content;
    margin-bottom: 12px;
    padding: 5px 12px;

    background: $grey-10;
    border-radius: $border-radius-sm;

    .meta-info__item {
      display: flex;
      align-items: center;
      column-gap: 8px;
    }

    .meta-info__label {
      color: $grey-80;
      @include body-1;
    }

    .meta-info__value {
      color: $grey-80;
      @include body-1;
    }
  }

  .subpage__table-wrapper {
    height: calc(100% - 44px);
  }
}
</style>
